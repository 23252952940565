.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(239, 245, 252);
}

body {
  background-color: rgb(239, 245, 252);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.top-section {
  display: flex;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .top-section {
    flex-direction: column;
  }
}

.chat-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.chat-box {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message.user {
  align-self: flex-end;
  background-color: #e6ffe6;
}

.message.assistant {
  align-self: flex-start;
  background-color: white;
}

.input-area {
  display: flex;
  padding: 20px;
  gap: 10px;
}

.input-area input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.input-area button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}