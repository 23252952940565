.app-header {
  background-color: #282c34;
  padding: 10px 20px;
  color: white;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  align-items: center;
}

.app-logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #61dafb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  object-fit: cover;
  margin-right: 15px;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

.auth-button {
  display: flex;
  align-items: center;
}

.auth-button > * {
  margin-left: 10px;
}

.auth-button button {
    background-color: #61dafb;
    color: #282c34;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .auth-button button:hover {
    background-color: #21a1c4;
  }